"use client";

import { supplierRoutes } from "@/src/constants/routes";
import { useRouter } from "next/navigation";
import { useEffect } from "react";

const REDIRECT_HOME_TIMEOUT = 10000;

export function useRedirectToHome() {
  const router = useRouter();

  useEffect(() => {
    setTimeout(() => {
      router.push(supplierRoutes.home);
    }, REDIRECT_HOME_TIMEOUT);
  }, []);
}

"use client";

import { InternalErrorPage } from "@/src/components/errorScreens/InternalErrorPage";
import { useEffect } from "react";
import * as Sentry from "@sentry/nextjs";
import useAppContext from "@/src/components/appContext/useAppContext";
export default function ErrorPage({
  error
}: {
  error: Error & {
    digest?: string;
  };
}) {
  const {
    ctx
  } = useAppContext();
  useEffect(() => {
    // Log the error to Sentry
    Sentry.captureException(error, {
      user: {
        email: ctx.user?.email,
        id: ctx.user?.supplierId,
        username: ctx.user?.name
      }
    });
  }, [error]);
  return <InternalErrorPage data-sentry-element="InternalErrorPage" data-sentry-component="ErrorPage" data-sentry-source-file="error.tsx" />;
}
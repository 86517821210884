"use client";

import { supplierRoutes } from "@/src/constants/routes";
import { Flex, Text, Title, theme } from "@/src/pattern-library";
import Link from "next/link";
import Image from "next/image";
import { useRedirectToHome } from "./useRedirectToHome";
const HEADER_HEIGHT = 64;
export function InternalErrorPageLayout({
  title,
  description
}: {
  title: string;
  description: string;
}) {
  const {
    token: {
      sizeXS,
      colorBorderSecondary
    }
  } = theme.useToken();
  useRedirectToHome();
  return <div data-sentry-component="InternalErrorPageLayout" data-sentry-source-file="InternalErrorPageLayout.tsx">
      <Flex align="center" justify="center" style={{
      height: HEADER_HEIGHT,
      background: "#fff",
      borderBottom: `1px solid ${colorBorderSecondary}`
    }} data-sentry-element="Flex" data-sentry-source-file="InternalErrorPageLayout.tsx">
        <Link href={supplierRoutes.home} data-sentry-element="Link" data-sentry-source-file="InternalErrorPageLayout.tsx">
          <Image src="/images/trustana_logo_with_text.svg" alt="Trustana Logo" width={160} height={32} data-sentry-element="Image" data-sentry-source-file="InternalErrorPageLayout.tsx" />
        </Link>
      </Flex>
      <Flex vertical gap={sizeXS} align="center" justify="center" className="absolute left-0 right-0 bottom-0 bg-[#F7F7FA]" style={{
      top: HEADER_HEIGHT
    }} data-sentry-element="Flex" data-sentry-source-file="InternalErrorPageLayout.tsx">
        <Title style={{
        margin: 0
      }} level={5} data-sentry-element="Title" data-sentry-source-file="InternalErrorPageLayout.tsx">
          {title}
        </Title>
        <Text size="SM" data-sentry-element="Text" data-sentry-source-file="InternalErrorPageLayout.tsx">{description}</Text>
      </Flex>
    </div>;
}